
// TO USE: wrap this component with an class have position relative and the same parrent with the button to show this

import { defineComponent } from "vue";

const __default__ = defineComponent({
  name: "MenuOptionDropdown",
  props: {
    indicatorPosition: {
      type: String,
      default: "10%",
    },
    indicatorLeft: {
      type: Boolean,
      default: false,
    },
    position: {
      type: String,
      default: "absolute",
    },
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "f843686e": (_ctx.position),
  "46fed451": (_ctx.indicatorLeft ? _ctx.indicatorPosition: _ctx.initial),
  "75037479": (_ctx.indicatorLeft ? _ctx.initial: _ctx.indicatorPosition)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__