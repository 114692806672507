import { defaultFontsize } from "@/constants/inputsVars";

export class FileInfo {
  id: string;
  name: string;
  type: "pdf";
  width?: number;
  height?: number;
  size?: number;
  scale = 1;
  data?: Blob;
  path: string;
  pageNums = 1;
  currentPage = 1;
  inputs: FileInput[] = [];
  contractId?: string;

  constructor(
    id: string,
    name: string,
    type: "pdf",
    size: number,
    data: Blob,
    path: string
  ) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.size = size;
    this.path = path;
    this.data = data;
  }
}
export class FileInput {
  id = "";
  contractFileId?: string = undefined;
  type = 1;
  page = 1;
  isOptionalText = true;
  signer?: string = "";
  text = "";
  stampFilePath?: string = "";
  isChecked = false;
  checkedByDefault = false;
  isFilled?: boolean = false;
  contractSignerOrder = 0;
  contractSignerId?: string | null = null;
  x = 0;
  y = 0;
  w = 0;
  h = 0;
  padding = 0;
  isRoundStamp = true;
  fontSize = defaultFontsize.id;
  minHeight = 0;
  scale = 1.0;

  get isStamp(): boolean {
    return this.type == 2;
  }

  constructor(vars?: ContractVars) {
    Object.assign(this, vars);
    if (!vars) return;
    this.x = vars.positionX;
    this.y = vars.positionY;
    this.w = vars.width;
    this.h = vars.height;
    this.isChecked = vars.checkedByDefault;
    this.isOptionalText = vars.isOptionalCheckbox;
  }

  cloneFields(): FileInput {
    const inputId = new Date().getTime().toString();
    return Object.assign(new FileInput(), {
      id: "texts" + inputId,
      contractFileId: this.contractFileId,
      type: this.type,
      page: this.page,
      signer: this.signer,
      text: this.text,
      isOptionalText: this.isOptionalText,
      isChecked: this.isChecked,
      checkedByDefault: this.checkedByDefault,
      contractSignerOrder: this.contractSignerOrder,
      x: this.x + 10,
      y: this.y + 10,
      w: this.w,
      h: this.h,
      padding: this.padding,
      fontSize: this.fontSize,
      scale: this.scale,
      minHeight: this.minHeight,
    });
  }
}
export interface ContractVars {
  id: string;
  contractFileId: string;
  page: number;
  type: number;
  positionX: number;
  positionY: number;
  width: number;
  height: number;
  text: string;
  stampFilePath: string;
  isChecked: boolean;
  checkedByDefault: boolean;
  isOptionalCheckbox: boolean;
  contractSignerId: string | null;
  contractSignerOrder: number;
  isFilled: boolean;
  fontSize: string;
}

export interface ContractFiles {
  id: string;
  contractId: string;
  order: number;
  fileName: string;
  fileType: "pdf";
  filePath: string;
  contractVars?: ContractVars[];
}

interface BasicTemplateInfoI {
  id: string | null;
  title: string;
  isPublished: boolean;
}

export class BasicTemplateInfo implements BasicTemplateInfoI {
  id: null | string = null;
  title = "";
  isPublished = false;

  constructor(info?: BasicTemplateInfoI) {
    if (!info) return;
    this.id = info.id;
    this.title = info.title;
    this.isPublished = info.isPublished;
  }
}

interface securityTemplateInfoI {
  isOnlyPartnerXIDRequired: boolean;
  isXIDRequired: boolean;
  is2FARequired: boolean;
  isSignerTransferAllowed: boolean;
  needTimeStamp: boolean;
}

export class securityTemplateInfo implements securityTemplateInfoI {
  isOnlyPartnerXIDRequired = false;
  isXIDRequired = false;
  is2FARequired = true;
  isSignerTransferAllowed = true;
  needTimeStamp = true;

  constructor(security?: securityTemplateInfoI) {
    if (!security) return;
    this.is2FARequired = security.is2FARequired;
    this.isXIDRequired = security.isXIDRequired;
    this.isOnlyPartnerXIDRequired = security.isOnlyPartnerXIDRequired;
    this.isSignerTransferAllowed = security.isSignerTransferAllowed;
    if(security.needTimeStamp) {
      this.needTimeStamp = security.needTimeStamp;
    } else {
      this.needTimeStamp = false;
    }
  }
}

export default interface Template
  extends BasicTemplateInfoI,
    securityTemplateInfoI {
  zipFilePath: string;
  createdBy: string;
  contractInformation: {
    contractId: string;
    isAutomaticallyUpdated: boolean;
  };
  contractSigners: [
    {
      id: string;
      contractId: string;
      placeHolder: string;
      order: number;
      statusName: string;
    }
  ];
  contractFiles: ContractFiles[];
}

export interface SimpleBuiltInTemplate {
  id: string;
  title: string;
  isPublished: boolean;
  zipFilePath: string;
  updatedAt: string;
}
