
import { defineComponent, reactive, ref, toRef } from "vue";
import MenuOptionDropdown from "@/components/atomics/dropdown/MenuOptionDropdown.vue";
import { onClickOutside } from "@vueuse/core";

export default defineComponent({
  name: "DropdownTrigger",
  components: {
    MenuOptionDropdown,
  },
  setup(_, { attrs }) {
    const isDropdownOpen = ref(false);
    const menuDropdown = ref<HTMLElement | null>(null);

    // pick attribute has menu- prefix and hand them to MenuOptionDropdown
    const menuPrefix = /^menu-/;
    const prefixKeys = Object.keys(attrs).filter((key) => menuPrefix.test(key));
    const menuAttrs = reactive(
      Object.fromEntries(
        prefixKeys.map((k) => [k.replace("menu-", ""), toRef(attrs, k)])
      )
    );

    onClickOutside(menuDropdown, () => (isDropdownOpen.value = false));

    return {
      isDropdownOpen,
      menuAttrs,
      menuDropdown,
      attrs,
    };
  },
});
