export const textfieldType = 1;
export const stampType = 2;
export const checkfieldType = 3;

export const fontSizes = [
  {
    id: "8",
    name: "8",
    mobVal: "7",
  },
  {
    id: "9",
    name: "9",
    mobVal: "8",
  },
  {
    id: "10",
    name: "10",
    mobVal: "9",
  },
  {
    id: "11",
    name: "11",
    mobVal: "10",
  },
  {
    id: "12",
    name: "12",
    mobVal: "11",
  },
  {
    id: "14",
    name: "14",
    mobVal: "12",
  },
];
export const defaultFontsize = fontSizes[2];

export const inputMinHeight = 40;
export const stampSizesMM = [15, 18, 21, 24];
export const stampSizeMMToPxRatio = 41 / 15; // 15mm ≈ 41px.
export const stampPadding = 10;
export const stampWidth = 100;
export const defaultStampSizeMM = stampSizesMM[2];

export const defaultTextfieldWidth = 120;
export const defaultTextfieldMinWidth = 120;
export const defaultTextfieldHeight = 40;
export const fieldPadding = 10;

export const defaultCheckfieldWidth = 100;
export const defaultCheckfieldMinWidth = 100;

export const stampTextMaxLenght = 18;
export const stampFontSize = 12 / 79; // Scale base on stamnp size.
export const stampFontSizeSmall = 10 / 79;
export const stampTextHeight = 15 / 79;
