import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3bc6ec8a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]
const _hoisted_2 = ["src"]
const _hoisted_3 = { key: 1 }
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["button text-button primary_button", { 'icon-button': _ctx.icon, disabled: !_ctx.enabled }]),
    disabled: !_ctx.enabled,
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onClick')))
  }, [
    (_ctx.icon)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          class: _normalizeClass(["icon", [_ctx.iconClass, { left: _ctx.isShowText }]]),
          src: _ctx.enabled ? _ctx.icon : _ctx.disabledIcon ?? _ctx.icon
        }, null, 10, _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.isShowText)
      ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.text), 1))
      : _createCommentVNode("", true),
    (_ctx.rightIcon)
      ? (_openBlock(), _createElementBlock("img", {
          key: 2,
          class: "icon right",
          src: _ctx.enabled ? _ctx.rightIcon : _ctx.disabledIcon ?? _ctx.rightIcon
        }, null, 8, _hoisted_4))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}