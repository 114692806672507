import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withModifiers as _withModifiers, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuOptionDropdown = _resolveComponent("MenuOptionDropdown")!

  return (_openBlock(), _createElementBlock("div", {
    class: "dropdown-wrapper",
    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isDropdownOpen = !_ctx.isDropdownOpen)),
    ref: "menuDropdown"
  }, [
    _renderSlot(_ctx.$slots, "dropdownTrigger", {}, undefined, true),
    (_ctx.isDropdownOpen)
      ? (_openBlock(), _createBlock(_component_MenuOptionDropdown, _mergeProps({
          key: 0,
          onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
        }, _ctx.menuAttrs, { class: "menu-dropdown" }), {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "dropdownContent", {}, undefined, true)
          ]),
          _: 3
        }, 16))
      : _createCommentVNode("", true)
  ], 512))
}