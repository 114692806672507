import { FileInfo, FileInput } from "@/models/Template";

import stampImage from "@/assets/images/stamp-orange.svg";
import textImage from "@/assets/images/text.svg";
import checkboxImage from "@/assets/images/checkbox.svg";

import {
  fontSizes,
  defaultStampSizeMM,
  stampPadding,
  stampWidth,
  stampSizeMMToPxRatio,
  defaultTextfieldWidth,
  defaultTextfieldHeight,
  defaultCheckfieldWidth,
  defaultFontsize,
  checkfieldType,
  textfieldType,
  defaultTextfieldMinWidth,
  inputMinHeight,
  fieldPadding,
} from "@/constants/inputsVars";

const textImg = new Image();
textImg.src = textImage;

const checkboxImg = new Image();
checkboxImg.src = checkboxImage;

const stampImg = new Image();
stampImg.src = stampImage;

export default function useInputVars() {
  const dragStamp = (dataTransfer: DataTransfer | null, file: FileInfo) => {
    if (!dataTransfer) return;
    dataTransfer.setData("field", "stamp");
    dataTransfer.setData("fileId", file.id);
    dataTransfer.setDragImage(stampImg, 25, 25);
  };

  const dragText = (dataTransfer: DataTransfer | null, file: FileInfo) => {
    if (!dataTransfer) return;
    dataTransfer.setData("field", "text");
    dataTransfer.setData("fileId", file.id);
    dataTransfer.setDragImage(textImg, 0, 0);
  };

  const dragCheckbox = (dataTransfer: DataTransfer | null, file: FileInfo) => {
    if (!dataTransfer) return;
    dataTransfer.setData("field", "checkbox");
    dataTransfer.setData("fileId", file.id);
    dataTransfer.setDragImage(checkboxImg, 0, 0);
  };

  const addVarFromDrag = (
    e: DragEvent,
    file: FileInfo,
    stampType: string,
    stampSize: number
  ) => {
    if (!e.dataTransfer || !e.target) return;
    const fieldType = e.dataTransfer.getData("field");
    const page = document
      .querySelector("#canvas-overlay")
      ?.getBoundingClientRect();

    if (!page) return;

    let actualX = Math.floor(
      e.pageX - page.left - (e.target as HTMLElement).offsetLeft
    );
    let actualY = Math.floor(
      e.pageY - page.top - (e.target as HTMLElement).offsetTop
    );

    const scale = file.scale || 1;

    if (fieldType === "stamp") {
      let defaultWidthOfStamp = getStampWidth();
      let defaultHeightOfStamp = getStampHeight(
        // TODO: stampSize || defaultStampSizeMM
        stampSize || defaultStampSizeMM
      );

      defaultWidthOfStamp = defaultWidthOfStamp * scale;
      defaultHeightOfStamp = defaultHeightOfStamp * scale;
      const padding = stampPadding * 2 * scale;

      if (actualX + defaultWidthOfStamp + padding > page.width)
        actualX = page.width - defaultWidthOfStamp - padding;
      else actualX = actualX - 75;

      if (actualX <= 0) actualX = 0;

      if (actualY + defaultHeightOfStamp + padding > page.height)
        actualY = page.height - defaultHeightOfStamp - padding;
      else actualY = actualY - 40;

      if (actualY <= 0) actualY = 0;

      addStamp(file, file.currentPage, {
        initX: Math.floor(actualX),
        initY: Math.floor(actualY),
        isMobile: false,
        isRound: stampType === "square" ? false : true,
        stampSize: stampSize,
      });
    } else if (fieldType === "text") {
      const defaultWidth = Math.floor(defaultTextfieldWidth * file.scale);
      const fontSize = Number(defaultFontsize.id) * 1.5;
      const defaultHeight = Math.max(
        fontSize * (file.scale ?? 1.0) + 2 * fieldPadding * (file.scale ?? 1.0),
        inputMinHeight
      );

      if (actualX + defaultWidth > page.width)
        actualX = page.width - defaultWidth;
      if (actualY + defaultHeight > page.height)
        actualY = page.height - defaultHeight;

      addText(file, false, file.currentPage, {
        initX: Math.floor(actualX > 0 ? actualX : 0),
        initY: Math.floor(actualY > 0 ? actualY : 0),
        isMobile: false,
      });
    } else if (fieldType === "checkbox") {
      const defaultWidth = Math.floor(defaultCheckfieldWidth * file.scale);
      const fontSize = Math.max(Number(defaultFontsize.id) * 1.5, 20);
      const defaultHeight = Math.max(
        fontSize * (file.scale ?? 1.0) + 2 * fieldPadding * (file.scale ?? 1.0),
        inputMinHeight
      );

      if (actualX + defaultWidth > page.width)
        actualX = page.width - defaultWidth;
      if (actualY + defaultHeight > page.height)
        actualY = page.height - defaultHeight;
      addText(file, true, file.currentPage, {
        initX: Math.floor(actualX > 0 ? actualX : 0),
        initY: Math.floor(actualY > 0 ? actualY : 0),
        isMobile: false,
      });
    }
  };

  const addStamp = (
    file: FileInfo,
    page: number,
    options = {
      initX: 0,
      initY: 0,
      isMobile: false,
      isRound: true,
      stampSize: 21,
    }
  ) => {
    let defaultWidthOfStamp = getStampWidth();
    let defaultHeightOfStamp = getStampHeight(options.stampSize);
    const scale = file.scale ?? 1;

    defaultWidthOfStamp = defaultWidthOfStamp * scale;
    defaultHeightOfStamp = defaultHeightOfStamp * scale;
    const inputId = new Date().getTime().toString();
    file.inputs.push(
      Object.assign(new FileInput(), {
        id: "stamps" + inputId,
        contractFileId: file.id,
        type: 2,
        page: page,
        contractSignerOrder: -1,
        isOptionalText: false,
        signer: "",
        text: "",
        isChecked: false,
        checkedByDefault: false,
        x: options.initX,
        y: options.initY,
        w: defaultWidthOfStamp,
        h: defaultHeightOfStamp,
        padding: stampPadding * scale,
        isRoundStamp: options.isRound,
        fontSize: defaultFontsize.id,
        minHeight: inputMinHeight,

        scale: file.scale ?? 1.0,
      })
    );
  };

  const addText = (
    file: FileInfo,
    isOptional: boolean,
    page: number,
    options = {
      initX: 0,
      initY: 0,
      isMobile: false,
    }
  ) => {
    let defaultWidth = isOptional
      ? defaultCheckfieldWidth
      : defaultTextfieldWidth;

    const fontSize = isOptional
      ? Math.max(Number(defaultFontsize.id) * 1.5, 20)
      : Number(defaultFontsize.id) * 1.5;

    let defaultHeight = Math.max(
      fontSize * (file.scale ?? 1.0) + 2 * fieldPadding * (file.scale ?? 1.0),
      inputMinHeight
    );

    if (options.isMobile) {
      if (isOptional) {
        defaultWidth = defaultTextfieldMinWidth;
        defaultHeight = defaultTextfieldHeight;
      } else {
        defaultWidth = defaultTextfieldMinWidth;
        defaultHeight = defaultTextfieldHeight;
      }
    } else {
      defaultWidth = Math.floor(defaultWidth);
      defaultHeight = Math.floor(defaultHeight);
    }

    const minHeight = Math.floor(inputMinHeight);
    const inputId = new Date().getTime().toString();
    file.inputs.push(
      Object.assign(new FileInput(), {
        id: "texts" + inputId,
        contractFileId: file.id,
        type: isOptional ? checkfieldType : textfieldType,
        page: page,
        contractSignerOrder: -1,
        isOptionalText: false,
        signer: "",
        text: "",
        isChecked: false,
        checkedByDefault: false,
        x: options.initX,
        y: options.initY,
        w: Math.floor(defaultWidth * (file.scale ?? 1.0)),
        h: defaultHeight,
        padding: 0,
        fontSize: defaultFontsize.id,
        minHeight: Math.max(minHeight, defaultHeight),
        scale: file.scale ?? 1.0,
      })
    );
  };

  const onRemoveInput = (file: FileInfo, fieldId: string) => {
    const fieldIndex = file.inputs.findIndex(
      (field: any) => field.id === fieldId
    );
    if (fieldIndex !== -1) (file.inputs as Array<any>).splice(fieldIndex, 1);
  };

  const onCopyInput = (file: FileInfo, fieldId: string) => {
    const cloneField = (
      file.inputs.find((field: FileInput) => field.id === fieldId) as FileInput
    )?.cloneFields();
    if (cloneField) file.inputs.push(cloneField);
  };

  const getStampPosition = (
    x: number,
    y: number,
    h: number,
    padding: number
  ) => ({
    positionX: x - (getStampWidth() - h) / 2 - padding,
    positionY: y - padding,
  });

  const getStampSize = (h: number) => ({
    width: getStampWidth(),
    height: h,
    padding: stampPadding,
  });

  const getSizeJson = (w: number, h: number, scale: number) => ({
    width: Math.floor(w / scale),
    height: Math.floor(h / scale),
  });

  const getStampSizeJson = (h: number, scale: number) => ({
    width: Math.floor(h / scale),
    height: Math.floor(h / scale),
  });

  const getPositionJson = (x: number, y: number, scale: number) => ({
    positionX: Math.floor(x / scale),
    positionY: Math.floor(y / scale),
  });

  const getStampPositionJson = (
    x: number,
    y: number,
    w: number,
    h: number,
    padding: number,
    scale: number
  ) => ({
    positionX: Math.floor((x + (w - h) / 2 + padding) / scale),
    positionY: Math.floor((y + padding) / scale),
  });

  const getStampWidth = () => {
    return stampWidth;
  };

  const getStampHeight = (stampSizeMM: number) => {
    return stampSizeMM * stampSizeMMToPxRatio;
  };

  const getFontSizeMobile = (fontSize: string): string => {
    return (
      fontSizes.find((e) => e.id == fontSize)?.mobVal ?? defaultFontsize.mobVal
    );
  };

  return {
    fontSizes: fontSizes.map((f) => f.id),

    addText,
    addStamp,
    onRemoveInput,
    onCopyInput,

    dragStamp,
    dragText,
    dragCheckbox,
    addVarFromDrag,

    getStampSizeJson,
    getStampPositionJson,
    getPositionJson,
    getSizeJson,
    getStampPosition,
    getStampSize,

    getStampWidth,
    getStampHeight,

    getFontSizeMobile,
  };
}
